<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchForm.usEmail"
            placeholder="APPID"
            clearable
        >
        </el-input>

      </el-form-item>

<!--      <el-form-item>
        <el-input
            v-model="searchForm.cardNo"
            placeholder="卡号"
            clearable
        >
        </el-input>

      </el-form-item>-->
<!--
      <el-form-item>
        <el-input
            v-model="searchForm.cardId"
            placeholder="商户卡ID"
            clearable
        >
        </el-input>

      </el-form-item>-->
<!--      <el-form-item>

          <el-date-picker
              v-model="value2"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"

              @change="dateFormat"
              >
          </el-date-picker>

      </el-form-item>-->


      <el-form-item>
        <el-select v-model="searchForm.type" placeholder="请选择开卡状态">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>

        <el-form-item>
            <el-button type="primary" @click="dialogVisible = true" v-if="hasAuth('shares:ceiling:save')">新增</el-button>
        </el-form-item>

<!--      <el-form-item>
        <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('card:card:delete')">批量删除</el-button>
        </el-popconfirm>
      </el-form-item>-->
    </el-form>

    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @cell-dblclick	="rowClick"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="50">
      </el-table-column>

      <el-table-column
          prop="appId"
          label="appId"
          width="180">
      </el-table-column>

      <el-table-column
          prop="apiKey"
          label="apiKey"
          width="150">
      </el-table-column>

      <el-table-column
          prop="mockApiKey"
          label="mockApiKey"
          width="150">
      </el-table-column>

      <el-table-column
          prop="state"
          label="是否停用"
          width="150">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.state === '0'" type="success">启用</el-tag>
          <el-tag size="small" v-else-if="scope.row.state === '1'" type="danger">停用</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="type"
          label="支付类型"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type === '1'" type="success">微         信</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '2'" type="primary">支付宝</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="rsaPrivateKey"
          label="rsaPrivateKey"
          width="500">
      </el-table-column>


      <el-table-column
          prop="creationDate"
          label="开卡日期"
          width="165">
      </el-table-column>


      <el-table-column
          prop="icon"
          width="260px"
          label="操作">

        <template slot-scope="scope">
          <el-button type="text" @click="editHandle(scope.row.id)">修改</el-button>

            <el-button type="text" @click="delHandle(scope.row.id)">删除</el-button>

        </template>
      </el-table-column>


    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[15, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>


    <!--新增对话框-->
    <el-dialog
        title="商户新增或修改"
        :visible.sync="dialogVisible"
        width="800px"
        :before-close="handleClose"
        >

      <el-form :model="editForm" :rules="editFormRules" ref="editForm">


        <el-form-item label="APPID"  prop="street" label-width="110px">
          <el-input v-model="editForm.appId" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="apiKey"  prop="street" label-width="110px">
          <el-input v-model="editForm.apiKey" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="mockApiKey"  prop="street" label-width="110px">
          <el-input v-model="editForm.mockApiKey" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="rsaPrivateKey"  prop="street" label-width="110px">
          <el-input
              type="textarea"
              :rows="10"
              placeholder=" "
              v-model="editForm.rsaPrivateKey">
          </el-input>

        </el-form-item>

        <el-form-item label="启用停用"  prop="state" label-width="110px">
          <el-radio-group v-model="editForm.state">
            <el-radio :label="'0'">启用</el-radio>
            <el-radio :label="'1'">停用</el-radio>
          </el-radio-group>
        </el-form-item>



        <el-form-item label="开卡状态"  prop="type" label-width="110px">
          <el-radio-group v-model="editForm.type">
            <el-radio :label="'1'">微信</el-radio>
            <el-radio :label="'2'">支付</el-radio>

          </el-radio-group>
        </el-form-item>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定 </el-button>
      </div>
    </el-dialog>




    <!-- 分配权限对话框 -->
    <el-dialog title="分配角色" :visible.sync="roleDialogFormVisible" width="600px">

      <el-form :model="roleForm">
        <el-tree
            :data="roleTreeData"
            show-checkbox
            ref="roleTree"
            :check-strictly=checkStrictly
            node-key="id"
            :default-expand-all=true
            :props="defaultProps">
        </el-tree>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {
      options: [{value: '',label: ''}
        ,{value: '1',label: '微  信'}
        ,{value: '2',label: '支付宝'}
       ],
      searchForm: {},
      delBtlStatu: true,

      total: 0,
      size: 15,
      current: 1,

      dialogVisible: false,
      editForm: {

      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一天',
          onClick(picker) {
            const end =new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));
            const start = new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0));
            picker.$emit('pick', [start, end]);

          }
        }, {
          text: '昨天',
          onClick(picker) {
            const start=new Date(new Date(new Date().getTime()-24*60*60*1000).setHours(0, 0, 0, 0));
            const end=new Date(new Date(new Date().getTime()-24*60*60*1000).setHours(23, 59, 59, 999));
            picker.$emit('pick', [start, end]);
          }
        }
      ,{
          text: '最近一周',
          onClick(picker) {
            const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
            const start = new Date();

            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);

          }
        }]
      },
      value2: '',
      tableData: [],

      editFormRules: {
        usEmail: [
          {required: true, message: '请输入所属用户邮箱', trigger: 'blur'}
        ],
        theSurname: [
          {required: true, message: '请输入姓', trigger: 'blur'}
        ],
        theName: [
          {required: true, message: '请输入名', trigger: 'blur'}
        ],
        cardNo: [
          {required: true, message: '请输入卡号', trigger: 'blur'}
        ],
        cvv: [
          {required: true, message: '请输入cvv', trigger: 'blur'}
        ],
        cardId: [
          {required: true, message: '请输入 商户卡ID', trigger: 'blur'}
        ]
      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleForm: {},
      roleTreeData:  [],
      treeCheckedKeys: [],
      checkStrictly: true

    }
  },
  created() {


    this.getUserList()



    this.$axios.get("/sys/roleMGQZHP/list").then(res => {
      this.roleTreeData = res.data.data.records
    })
  },
  methods: {

    dateFormat(picker) {
      /* 法一 */
      console.log("11"+picker.length)
      console.log(picker[0].toString())
      console.log(picker[1].toString())
      this.searchForm.creationTime= picker[0].toString()+","+picker[1].toString();
     /* this.params.startTime = picker[0].toString()
      this.params.endTime = picker[1].toString()*/
      /* 法二 */
      // let startDate = this.params.date.toString()
      // this.params.startTime = startDate.substring(0, 10)
      // this.params.endTime = startDate.substring(11)
    },
    rowClick(row,column,event) {
      this.editHandle(row.id)
      console.log(row);///获取该行的所有数据
      console.log(column);
      console.log(event);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getUserList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },

    getUserList() {

    /*  this.$nextTick(() => {
        // 在此处执行你要执行的函数
        console.log(" 1 =" +this.searchForm.creationTime[0])
      });
      console.log(" 2 =" +this.searchForm.creationTime)
      this.searchForm.creationTime=this.searchForm.creationTime[0];
      console.log(" 2 =" +this.searchForm.creationTime)*/
      if(this.value2==null || this.value2==''){
        console.log("112"+this.value2)
        this.searchForm.creationTime='';
      }


      this.$axios.get("/card/paySetup/listS", {
        params: {
          usEmail: this.searchForm.usEmail,
          cardNo: this.searchForm.cardNo,
          cardId: this.searchForm.cardId,
          type: this.searchForm.type,
          creationTime: this.searchForm.creationTime,

          current: this.current,
          size: this.size
        }
      }).then(res => {
        this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total

        //this.value2=[new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)];
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert((this.editForm.id?'update' : 'save'))
          this.$axios.post('/card/paySetup/' + (this.editForm.id?'update' : 'save'), this.editForm)
              .then(res => {

                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose:() => {
                    this.getUserList()
                  }
                });

                this.dialogVisible = false
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get('/card/paySetup/info/' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    editHandleT(id) {
      this.$axios.get('http://visa.fomepay.com/APay/page/share/showcard.html?cardId=' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);


      this.$axios.post("/card/paySetup/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });
      })
    },
    sa(id) {
      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);
      this.$axios.post("/card/card/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });
      })
    },

    roleHandle (id) {
      this.roleDialogFormVisible = true

      this.$axios.get('/sys/userWQKKDUXHAL/info/' + id).then(res => {
        this.roleForm = res.data.data

        let roleIds = []
        res.data.data.sysRoles.forEach(row => {
          roleIds.push(row.id)
        })

        this.$refs.roleTree.setCheckedKeys(roleIds)
      })
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys()

      console.log(roleIds)

      this.$axios.post('/sys/userWQKKDUXHAL/role/' + this.roleForm.id, roleIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });

        this.roleDialogFormVisible = false
      })
    },
    repassHandle(id, username) {

      this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/sys/userWQKKDUXHAL/repass", id).then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success',
            onClose: () => {
            }
          });
        })
      })
    }
  }
}
</script>

<style scoped>

.el-pagination {
  float: right;
  margin-top: 22px;
}

</style>